import {Card, CardBody, Skeleton, SkeletonText} from '@chakra-ui/react'

const PropertyCardSkeleton = () => {
    return (
        <>
            <Skeleton height="200px"/>
            <CardBody>
                <SkeletonText/>
            </CardBody>
        </>
    )
};

export default PropertyCardSkeleton;