import { AspectRatio, Box, Image } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Scrollbar, A11y, EffectFade } from "swiper";
// import {Image as ImageProps} from "../entities/image";
import { Image as ImageProps } from "../entities/APIResponse";

// Import Swiper styles

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "../SwiperStyles.css";

interface Props {
  images: ImageProps[];
  size?: "sm" | "lg";
  // altImages: string;
}

const CarouselImages = ({ images, size }: Props) => {
  const renderSlide = (src: string, key?: string | number) => (
    <SwiperSlide key={key}>
      <AspectRatio
        ratio={16 / 9}
        overflow="hidden"
        width={size ? "75%" : "100%"}
        margin="auto"
        marginTop={size ? "3rem" : "0"}
      >
        <Image
          src={src}
          fallbackSrc="https://via.placeholder.com/300"
          objectFit="cover"
          overflow="hidden"
          filter="contrast(117%) brightness(84%) saturate(114%)"
          width="50%"

          // alt={altImages}
        />
      </AspectRatio>
    </SwiperSlide>
  );

  // const renderVideoSlide = (src: string, key?: string | number) => (
  //     <SwiperSlide key={key}>
  //         <AspectRatio ratio={16 / 9}>
  //             <video
  //                 src={src}
  //                 controls
  //                 width="100%"
  //                 style={{borderTopRightRadius: "9px", borderTopLeftRadius: "9px"}}
  //             />
  //         </AspectRatio>
  //     </SwiperSlide>
  // );

  return (
    <Swiper
      pagination={{
        clickable: true,
        ...(size === "lg" ? { type: "fraction" } : { dynamicBullets: true }),
      }}
      effect={"fade"}
      navigation={true}
      loop={true}
      modules={[EffectFade, Navigation, Pagination, Scrollbar, A11y]}
      className="mySwiper"
      style={
        size === "lg"
          ? {
              width: "100%",
              height: "100%",
              paddingTop: "5rem",
            }
          : {}
      }
    >
      {images?.length
        ? images.map(({ id, image }) => renderSlide(image, id))
        : renderSlide("https://via.placeholder.com/300")}
    </Swiper>
  );
};

export default CarouselImages;
