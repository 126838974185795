import {
  Box,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Collapse,
  Divider,
  Flex,
  HStack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import IconDetails from "./IconDetails";
import { Property } from "../entities/APIResponse";
import CarouselImages from "./Slider";
import ModalFormContact from "./ModalFormContact";
import WhatsAppButton from "./Buttons/WhatsAppButton";
import ContactButton from "./Buttons/ContactButton";
import ShareButton from "./Buttons/ShareButton";
import ShareModal from "./ShareModal";

interface Props {
  property: Property;
}

interface EstadoMap {
  [key: string]: string | undefined;
  EV: string;
  AQ: string;
}

const PropertyCard: React.FC<Props> = ({ property }) => {
  const {
    code,
    images,
    price,
    estado,
    canton,
    address,
    tipo_propiedad,
    area_construccion,
    bedrooms,
    bathrooms,
    short_description,
    slug,
  } = property;

  const estadoMap: EstadoMap = {
    EV: "Venta",
    AQ: "Alquiler",
  };

  const getEstado = (estado: string) => estadoMap[estado] || "";

  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 1024px)");

  useEffect(() => {
    if (!isMobile) {
      onClose();
    }
  }, [isMobile, onClose]);

  const handleMouseEnter = () => {
    if (!isMobile) onOpen();
  };

  const handleMouseLeave = () => {
    if (!isMobile) onClose();
  };

  const mensajeWhatsApp = useMemo(
    () =>
      `Hola, estoy interesado en esta propiedad, codigo:${code}. imagen:${
        images[0]?.image || "no disponible"
      }`,
    [code, images]
  );

  const commonButtonProps = {
    variant: "link",
    marginY: 5,
    width: "100%",
    justifyContent: "center",
  };

  return (
    <>
      <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <CarouselImages
          images={images}
          // altImages={property.title}
        />
        <Box position="absolute" top={3} right={3}>
          <ShareButton />
        </Box>
        <Link to={`/propiedades/${slug}`}>
          <CardHeader
            paddingY="4px"
            paddingX="18px"
            transition="color 0.15s ease-in-out"
            _hover={{
              color: "#CB913C",
              cursor: "pointer",
            }}
          >
            <Flex justifyContent="space-between">
              <Text fontSize="2xl" fontWeight="bold">
                ${price.toLocaleString("es-CO")}
              </Text>
              <Text fontSize="lg">{estado ? getEstado(estado) : "Estado no disponible"}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text fontSize="md" colorScheme="blackAlpha.500" fontWeight="medium">
                {canton},&nbsp;
                {address}
              </Text>
              {/*{propiedad..coordinates?.length && (*/}
              {/*    <Icon as={BiMap} boxSize={7} paddingBottom="5px"/>*/}
              {/*)}*/}
            </Flex>
          </CardHeader>
        </Link>

        <Button
          {...commonButtonProps}
          onClick={onToggle}
          display={{
            base: "flex",
            md: "flex",
            xl: "none",
          }}
        >
          <Text fontSize={15}>{isOpen ? "Ver menos" : "Ver más"}</Text>
        </Button>
        <Collapse in={isOpen}>
          <CardBody padding={0} marginX={5}>
            <Divider borderWidth="1px" />
            <HStack justifyContent="space-between" marginY={1}>
              <IconDetails
                tipoPropiedad={tipo_propiedad}
                areaConstruccion={area_construccion}
                bedrooms={bedrooms}
                bathrooms={bathrooms}
              />
            </HStack>
            <Divider borderWidth="1px" />
            <Text fontSize="md" marginY={2}>
              {short_description}
            </Text>
            <Divider borderWidth="1px" />
          </CardBody>
          <CardFooter>
            <Flex gap={3}>
              <ContactButton />
              <WhatsAppButton mensaje={mensajeWhatsApp} />
            </Flex>
          </CardFooter>
        </Collapse>
      </Box>
      <ModalFormContact />
      <ShareModal title={property.title} />
    </>
  );
};

export default PropertyCard;
