import {Button, Container, Text} from "@chakra-ui/react";
import {useState} from "react";

interface Props {
    children: string;
}

const ExpandableText = ({children}: Props) => {
    const [expanded, setExpanded] = useState(false);

    const limit = 300;

    const text = expanded ? children : children.substring(0, limit) + "...";
    const lines = text.split(/\r\n/);

    return (
        <Container fontSize="xl" padding={0} width="100%">
            {lines.map((line, index) => (
                <Text as='p' key={index}>
                    {line}
                </Text>
            ))}
            {children.length > limit &&
				<Button
					marginLeft={2}
					size="md"
					variant="link"
					onClick={() => setExpanded(!expanded)}
					color={expanded ? "blue.500" : "gray.500"}
				>
                    {expanded ? "Mostrar menos" : "Mostrar más"}
				</Button>
            }
        </Container>
    );
};

export default ExpandableText;