import { Button, Text, useColorModeValue } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import usePropertyQueryStore from "../../store/usePropertyQueryStore";

interface Props {
  name: string;
  ruta: string;
}

const AsideButton = ({ name, ruta }: Props) => {
  const clearFilters = usePropertyQueryStore((s) => s.setClearFilters);

  const bg = useColorModeValue("gray.700", "white");
  const color = useColorModeValue("white", "gray.800");

  return (
    <Button
      my={2}
      as={RouterLink}
      color={bg}
      bg={color}
      to={ruta}
      _hover={{
        background: bg,
        color: color,
      }}
      onClick={clearFilters}
    >
      <Text textAlign="left" fontSize="2xl" fontWeight="bold">
        {name}
      </Text>
    </Button>
  );
};

export default AsideButton;
