import { Box, SimpleGrid } from "@chakra-ui/react";
import PropertyCard from "./PropertyCard";
import useProperties from "../hooks/useProperties";
import PropertyCardContainer from "./PropertyCardContainer";
import PropertyCardSkeleton from "./PropertyCardSkeleton";

const PropertyGrid = () => {
  const { data, error, isLoading } = useProperties();

  const skeletons = [1, 2, 3, 4, 5, 6, 7, 8];

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <SimpleGrid
      columns={{
        base: 1,
        md: 2,
        lg: 3,
        xl: 3,
        "2xl": 4,
      }}
      spacing={5}
      padding="10px"
    >
      {isLoading &&
        skeletons.map((skeleton) => (
          <PropertyCardContainer key={skeleton}>
            <PropertyCardSkeleton />
          </PropertyCardContainer>
        ))}
      {data?.results.map((feature) => (
        <Box key={feature.id}>
          <PropertyCardContainer key={feature.id}>
            <PropertyCard property={feature} />
          </PropertyCardContainer>
        </Box>
      ))}
    </SimpleGrid>
  );
};

export default PropertyGrid;
