import { List, ListItem, Text, Button, Box, Icon, useColorModeValue } from "@chakra-ui/react";
import { propertiesArray } from "../services/icon-map";
import usePropertyQueryStore from "../store/usePropertyQueryStore";
import AsideButton from "./Buttons/AsideButton";

const AsideBar = () => {
  const bg = useColorModeValue("gray.700", "white");
  const color = useColorModeValue("white", "gray.800");

  const selectedProperty = usePropertyQueryStore((s) => s.propertyQuery.tipo_propiedad);
  const setSelectedProperty = usePropertyQueryStore((s) => s.setTipoPropiedad);

  return (
    <Box marginTop="15px">
      <AsideButton name="Inicio" ruta="/" />
      <List marginBottom={3}>
        {propertiesArray.map(({ id, icon, name }) => (
          <ListItem key={id}>
            <Button
              bg={selectedProperty === id ? bg : color}
              color={selectedProperty === id ? color : bg}
              marginY={3}
              paddingY={4}
              variant="ghost"
              fontWeight={selectedProperty === id ? "bold" : "medium"}
              onClick={() => setSelectedProperty(id)}
              leftIcon={<Icon as={icon} boxSize={6} />}
              _active={{
                background: color,
                color: bg,
              }}
              _hover={{
                background: bg,
                color: color,
              }}
            >
              <Text fontSize={selectedProperty === id ? "2xl" : "xl"}>{name}</Text>
            </Button>
          </ListItem>
        ))}
      </List>
      <AsideButton name="Mapa" ruta="/mapa" />
      <AsideButton name="Acerca de" ruta="/conocenos" />
    </Box>
  );
};

export default AsideBar;
