import { AiOutlineHome as casa } from "react-icons/ai";
import { RxBorderAll as terreno } from "react-icons/rx";
import { HiOutlineOfficeBuilding as oficina } from "react-icons/hi";
import { MdApartment as departamento } from "react-icons/md";
import { MdOutlineWarehouse as quinta } from "react-icons/md";
import { FaRegBuilding as edificio } from "react-icons/fa";
import { GiFamilyHouse as villa } from "react-icons/gi";

export const propertiesArray = [
  { id: "CS", icon: casa, name: "Casas", singularName: "Casa" },
  { id: "TR", icon: terreno, name: "Terrenos", singularName: "Terreno" },
  { id: "OF", icon: oficina, name: "Oficinas", singularName: "Oficina" },
  { id: "AP", icon: departamento, name: "Deptos.", singularName: "Depto." },
  { id: "QU", icon: quinta, name: "Quintas", singularName: "Quinta" },
  { id: "ED", icon: edificio, name: "Edificios", singularName: "Edificio" },
  { id: "VL", icon: villa, name: "Villas", singularName: "Villa" },
];
const iconMap = (tipo_propiedad: string) => {
  return propertiesArray.find((p) => p.id === tipo_propiedad);
};

export default iconMap;
