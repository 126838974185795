import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  HStack,
} from "@chakra-ui/react";
import Slider from "./Slider";
import { Image } from "../entities/APIResponse";
import { useModalImageStore } from "../store/modalStore";
import ShareButton from "./Buttons/ShareButton";
import ShareModal from "./ShareModal";

interface Props {
  imagenes: Image[];
  size?: "sm" | "lg";
}

const ImageModal = ({ imagenes, size }: Props) => {
  const { closeModal, isOpen } = useModalImageStore();

  return (
    <>
      <Modal onClose={closeModal} isOpen={isOpen} size="full">
        <ModalOverlay />
        <ModalContent bg="black">
          <HStack
            width="100%"
            justifyContent="space-between"
            paddingTop={9}
            paddingX={24}
          >
            <ModalCloseButton
              color="white"
              size="lg"
              position="static"
              width="90px"
              height="30px"
              cursor={"pointer"}
              zIndex={99}
              _hover={{
                bg: "gray.700",
              }}
            >
              X Cerrar
            </ModalCloseButton>
            <ShareButton />
          </HStack>
          <ModalBody position="absolute" top={0} zIndex={9} width="100%">
            <Slider images={imagenes} size={size} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <ShareModal title="Compartir" />
    </>
  );
};

export default ImageModal;
