import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useGoogleMap } from "../../hooks/useGoogleMap";

interface Props {
  lat: number;
  lng: number;
}

const MapDetailPage = ({ lat, lng }: Props) => {
  const { isLoaded, onLoad, onUnmount } = useGoogleMap();

  const center = {
    lat: lat || -2.890359,
    lng: lng || -78.77785,
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "100%" }}
      zoom={14}
      center={{ lat: center.lat, lng: center.lng }}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <MarkerF position={new window.google.maps.LatLng(center.lat, center.lng)}></MarkerF>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default MapDetailPage;
