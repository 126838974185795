import { Show, GridItem, Grid, Box, Flex } from "@chakra-ui/react";
import Nav from "../components/NavBar";
import AsideBar from "../components/AsideBar";
import PropertyGrid from "../components/PropertyGrid";
import PropertyHeading from "../components/PropertyHeading";
import PropertyRangePriceSelector from "../components/PropertyRangePriceSelector";
import PropertyStateSelector from "../components/PropertyStateSelector";

const HomePage = () => {
  return (
    <>
      <Nav />
      <Grid
        marginTop="21px"
        templateAreas={{
          base: `"main"`,
          lg: `"aside  main"`,
        }}
        templateColumns={{
          base: "1fr",
          lg: "183px 1fr",
        }}
      >
        <Show above="lg">
          <GridItem gridArea="aside">
            <AsideBar />
          </GridItem>
        </Show>
        <GridItem gridArea="main">
          <Box paddingLeft={2}>
            <PropertyHeading />
            <Flex marginBottom={5}>
              <PropertyStateSelector />
              <PropertyRangePriceSelector />
            </Flex>
          </Box>
          <Box>
            <PropertyGrid />
          </Box>
        </GridItem>
      </Grid>
    </>
  );
};

export default HomePage;
