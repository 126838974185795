import { extendTheme, type ThemeConfig } from "@chakra-ui/react";

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: "dark",
  // useSystemColorMode: false,
};

// 3. extend the theme
const theme = extendTheme({
  config,
  fonts: {
    heading: `'Roboto', sans-serif`,
    body: `'Poppins', sans-serif`,
  },
  colors: {
    buttonBg: {
      light: "orange.400", // color en modo claro
      dark: "orange.800", // color en modo oscuro
    },
    black: "#000",
    white: "#fff",
    // gray: {
    //   50: "#f9f9f9",
    //   100: "#ededed",
    //   200: "#d3d3d3",
    //   300: "#b3b3b3",
    //   400: "#808080",
    //   500: "#898989",
    //   600: "#6c6c6c",
    //   700: "#202020",
    //   800: "#121212",
    //   900: "#111",
    // },
    blue: {
      50: "#f9f9fa",
      100: "#edeeef",
      200: "#dfe1e3",
      300: "#d1d3d6",
      400: "#c1c4c8",
      500: "#afb3b8",
      600: "#9ba0a6",
      700: "#838a91",
      800: "#656d75",
      900: "#36404b",
    },
  },
});

export default theme;

// blue: {
//     50: "#f9f9fa",
//     100: "#edeeef",
//     200: "#dfe1e3",
//     300: "#d1d3d6",
//     400: "#c1c4c8",
//     500: "#afb3b8",
//     600: "#9ba0a6",
//     700: "#838a91",
//     800: "#656d75",
//     900: "#36404b",
//   },
