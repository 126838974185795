import { create } from "zustand";

interface PropertyQuery {
  estado?: string;
  tipo_propiedad?: string;
  searchText?: string;
  price_range_min?: number;
  price_range_max?: number;
}

interface PropertyQueryStore {
  propertyQuery: PropertyQuery;
  setSearchText: (searchText: string) => void;
  setEstado: (estado: string) => void;
  setTipoPropiedad: (tipo_propiedad: string) => void;
  setPriceRangeMin: (price_range_min: number) => void;
  setPriceRangeMax: (price_range_max: number) => void;
  setClearFilters: () => void;
}

const usePropertyQueryStore = create<PropertyQueryStore>((set) => ({
  propertyQuery: {},
  setSearchText: (searchText) => set(() => ({ propertyQuery: { searchText } })),
  setEstado: (estado) =>
    set((store) => ({ propertyQuery: { ...store.propertyQuery, estado } })),
  setTipoPropiedad: (tipo_propiedad) =>
    set((store) => ({
      propertyQuery: { ...store.propertyQuery, tipo_propiedad },
    })),
  setPriceRangeMin: (price_range_min) =>
    set((store) => ({
      propertyQuery: { ...store.propertyQuery, price_range_min },
    })),
  setPriceRangeMax: (price_range_max) =>
    set((store) => ({
      propertyQuery: { ...store.propertyQuery, price_range_max },
    })),
  setClearFilters: () => set({ propertyQuery: {} }),
}));

export default usePropertyQueryStore;
