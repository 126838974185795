import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useFormContact from "../hooks/useFormContact";
import useModalContactStore from "../store/modalStore";

const ModalFormContact = () => {
  const { closeModal, isOpen } = useModalContactStore();

  const validationSchema = Yup.object({
    name: Yup.string().required("El nombre es obligatorio"),
    email: Yup.string()
      .email("Email no válido")
      .required("El email es obligatorio"),
    message: Yup.string().required("El mensaje es obligatorio"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      phone: "",
    },
    validationSchema,
    onSubmit: (values) => {
      mutate(values);
    },
  });
  const { mutate, isLoading } = useFormContact({ resetForm: formik.resetForm });

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Contáctanos</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form onSubmit={formik.handleSubmit}>
            <FormControl
              isInvalid={!!(formik.touched.name && formik.errors.name)}
            >
              <FormLabel>Nombre Completo</FormLabel>
              <Input
                focusBorderColor="orange.200"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Nombre Completo"
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={!!(formik.touched.phone && formik.errors.phone)}
            >
              <FormLabel>Teléfono</FormLabel>
              <Input
                focusBorderColor="orange.200"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Teléfono"
              />
              <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={!!(formik.touched.email && formik.errors.email)}
            >
              <FormLabel>Correo Electrónico</FormLabel>
              <Input
                focusBorderColor="orange.200"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Correo Electrónico"
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={!!(formik.touched.message && formik.errors.message)}
            >
              <FormLabel>Mensaje</FormLabel>
              <Textarea
                focusBorderColor="orange.200"
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Escribe aquí..."
                size="sm"
              />
              <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
            </FormControl>
            <ModalFooter justifyContent="flex-start" padding={0} marginTop={6}>
              <Button
                colorScheme="orange"
                mr={3}
                type="submit"
                isLoading={isLoading}
                disabled={isLoading || !formik.isValid || !formik.dirty}
              >
                Enviar
              </Button>
              <Button onClick={closeModal} disabled={isLoading}>
                Cancelar
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalFormContact;
