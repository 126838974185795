import { AspectRatio, Box, Flex, Grid, GridItem, Show } from "@chakra-ui/react";
import AsideBar from "../AsideBar";
import Map from "./Map";
import PropertyRangePriceSelector from "../PropertyRangePriceSelector";
import PropertyStateSelector from "../PropertyStateSelector";
import NavBar from "../NavBar";

const GridMap = () => {
  return (
    <>
      <NavBar />
      <Grid
        templateAreas={{
          base: `"main"`,
          lg: `"aside  main"`,
        }}
        templateColumns={{
          base: "1fr",
          lg: "183px 1fr",
        }}
      >
        <Show above="lg">
          <GridItem gridArea="aside" marginTop="36px">
            <AsideBar />
          </GridItem>
        </Show>
        <GridItem gridArea="main" marginTop={9}>
          <Box>
            <Flex marginBottom={5}>
              <PropertyStateSelector />
              <PropertyRangePriceSelector />
            </Flex>
          </Box>

          <Map />
        </GridItem>
      </Grid>
    </>
  );
};

export default GridMap;
