import { Button, Icon } from "@chakra-ui/react";
import { TbGridDots } from "react-icons/tb";
import { useModalImageStore } from "../../store/modalStore";

interface Props {
  numeroFotos: number;
}

const ImageModalButton = ({ numeroFotos }: Props) => {
  const { openModal } = useModalImageStore();
  return (
    <Button
      position="absolute"
      onClick={openModal}
      fontSize="md"
      bg="gray.100"
      color="black"
      top="80%"
      right="1%"
      size="sm"
      leftIcon={<Icon as={TbGridDots} boxSize="21px" zIndex={9} />}
      _hover={{
        bg: "gray.200",
      }}
    >
      Ver galería ({numeroFotos} {numeroFotos === 1 ? "foto" : "fotos"})
    </Button>
  );
};

export default ImageModalButton;
