import { Button } from "@chakra-ui/react";
import { FaWhatsapp } from "react-icons/fa";

interface Props {
  mensaje: string;
  size?: string;
}
const WhatsAppButton = ({ mensaje, size }: Props) => {
  const numero = "+593991593055";
  const handleMessage = () => {
    window.open(`https://wa.me/${numero}?text=${encodeURIComponent(mensaje)}`);
  };
  return (
    <Button
      color="white"
      width="63%"
      onClick={handleMessage}
      leftIcon={<FaWhatsapp size="22px" />}
      bg="green.400"
      size={size || "sm"}
      _hover={{ bg: "green.300" }}
    >
      WhatsApp
    </Button>
  );
};

export default WhatsAppButton;
