import { HStack, Skeleton, Container, Grid, GridItem } from "@chakra-ui/react";

const PropertyDetailSkeleton = () => {
  return (
    <>
      <Container maxWidth="1200px" marginTop="99px">
        <HStack justifyContent="space-between" marginTop={6} marginBottom={3}>
          <Skeleton height="40px" width="400px" />
        </HStack>
        <Grid
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(4, 1fr)"
          gap={3}
          overflow="hidden"
          width="100%"
          height="480px"
        >
          <GridItem
            rowSpan={2}
            colSpan={2}
            overflow="hidden"
            borderTopLeftRadius="2xl"
            borderBottomLeftRadius="2xl"
          >
            <Skeleton height="100%" width="100%" />
          </GridItem>
          <GridItem colSpan={2} overflow="hidden" borderTopEndRadius="2xl">
            <Skeleton height="100%" width="100%" />
          </GridItem>
          <GridItem
            colSpan={2}
            bg="blackAlpha.500"
            overflow="hidden"
            borderEndEndRadius="2xl"
            position="relative"
          >
            <Skeleton height="100%" width="100%" />
          </GridItem>
        </Grid>
      </Container>
    </>
  );
};

export default PropertyDetailSkeleton;
