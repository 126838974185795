// useGoogleMap.js
import { useState, useCallback } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

export function useGoogleMap() {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const googleMapsApiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    region: "EC",
    googleMapsApiKey: googleMapsApiKey as string,
  });

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  return { map, isLoaded, onLoad, onUnmount };
}
