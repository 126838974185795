import { Input, InputGroup, InputLeftElement, Box } from "@chakra-ui/react";
import { useRef } from "react";
import { BsSearch } from "react-icons/bs";
import usePropertyQueryStore from "../store/usePropertyQueryStore";

const SearchInput = () => {
  const ref = useRef<HTMLInputElement>(null);
  const setSearchText = usePropertyQueryStore((s) => s.setSearchText);

  return (
    <Box flex={1}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (ref.current) {
            setSearchText(ref.current.value);
          }
        }}
      >
        <InputGroup>
          <InputLeftElement children={<BsSearch />} />
          <Input
            name="search"
            id="search"
            focusBorderColor="orange.200"
            ref={ref}
            borderRadius={6}
            placeholder="Buscar Propiedades..."
            variant="filled"
          />
        </InputGroup>
      </form>
    </Box>
  );
};

export default SearchInput;
