import {Button, Menu, MenuButton, MenuItem, MenuList} from "@chakra-ui/react";
import {BsChevronDown} from "react-icons/bs";
import useEstado from "../hooks/useEstado";
import usePropertyQueryStore from "../store/usePropertyQueryStore";

const PropertyStateSelector = () => {
    const estado = [
        {id: 1, estado: 'AQ', name: 'Alquiler'},
        {id: 2, estado: 'EV', name: 'Venta'}
    ]

    const setSelectedEstado = usePropertyQueryStore(s => s.setEstado)
    const selectedEstado = usePropertyQueryStore(s => s.propertyQuery.estado)

    const selectedEstadoName = useEstado(selectedEstado) || 'Alquiler o Venta'

    return (
        <>
            <Menu >
                <MenuButton as={Button} rightIcon={<BsChevronDown/>} borderRadius={4}>
                    {selectedEstadoName}
                </MenuButton>
                <MenuList zIndex={999}>
                    {estado.map(item =>
                        <MenuItem key={item.id} onClick={() => setSelectedEstado(item.estado)}>
                            {item.name}
                        </MenuItem>)}
                </MenuList>
            </Menu>
        </>
    );
};

export default PropertyStateSelector;
