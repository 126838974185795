import { Card } from "@chakra-ui/react";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}
const PropertyCardContainer = ({ children }: Props) => {
  return (
    <Card
      borderRadius={10}
      boxShadow="dark-lg"
      overflow="hidden"
      _hover={{
        transform: "scale(1.03)",
        transition: "transform .15s ease-in",
        zIndex: 99,
      }}
    >
      {children}
    </Card>
  );
};

export default PropertyCardContainer;
