import {
    AspectRatio,
    Box,
    ButtonGroup,
    Card,
    CardHeader,
    Flex,
    HStack,
    Icon,
    IconButton,
    Spacer,
    Text,
    VStack,
} from "@chakra-ui/react";
import {IoChevronBackOutline} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import {Property} from "../entities/APIResponse";
import ContactButton from "./Buttons/ContactButton";
import ShareButton from "./Buttons/ShareButton";
import WhatsAppButton from "./Buttons/WhatsAppButton";
import ExpandableText from "./ExpandableText";
import IconDetails from "./IconDetails";
import MapDetailPage from "./Map/MapDetailPage";
import ModalFormContact from "./ModalFormContact";
import ShareModal from "./ShareModal";
import Slider from "./Slider";

interface Props {
    property: Property;
}

const PropertyDetailMobile = ({property}: Props) => {
    const mensajeWhatsApp = `Hola, estoy interesado en esta propiedad, codigo:${
        property.code
    }. imagen:${property.images[0]?.image || "no disponible"}`;
    const navigate = useNavigate();
    const lat = property.latitud || -2.890359;
    const lng = property.longitud || -78.77785;
    const images = property.images || [];

    return (
        <>
            <Box width="100%">
                <HStack
                    justifyContent="space-between"
                    position="absolute"
                    zIndex={9}
                    width="100%"
                    paddingTop={2}
                    paddingX={2}
                >
                    <IconButton
                        aria-label="Retroceder"
                        boxSize="39px"
                        width="39px"
                        height="39px"
                        icon={<Icon as={IoChevronBackOutline} right="-100%" boxSize={6}/>}
                        onClick={() => navigate(-1)}
                        color="gray.700"
                        bg={"whiteAlpha.700"}
                        borderRadius="100%"
                    />
                    <ShareButton/>
                </HStack>
                <Slider images={images}/>
            </Box>
            <Box paddingX={3} marginTop={2}>
                <Flex
                    direction={{
                        base: "column",
                        md: "row",
                    }}
                    gap={{
                        base: 2,
                        md: 0,
                    }}
                >
                    <Box>
                        <Text
                            as="h3"
                            fontWeight="bold"
                            fontSize={{
                                base: "2xl",
                                md: "3xl",
                            }}
                        >
                            {property.title}
                        </Text>

                        <Text
                            fontSize={{
                                base: "xl",
                                md: "xl",
                            }}
                        >
                            {property.canton},&nbsp;
                            {property.address}
                        </Text>
                    </Box>
                    <Spacer/>
                    <HStack
                        justifyContent={{
                            base: "space-around",
                            md: "flex-end",
                        }}
                        gap={2}
                        width={{
                            base: "100%",
                            md: "39%",
                        }}
                    >
                        <IconDetails
                            tipoPropiedad={property.tipo_propiedad || "casa"}
                            areaConstruccion={property.area_construccion || 0}
                            bedrooms={property.bedrooms || 0}
                            bathrooms={property.bathrooms || 0}
                            size="lg"
                        />
                    </HStack>
                </Flex>

                <VStack alignItems="flex-start" width="100%">
                    <Text as="h3" fontSize="3xl" fontWeight="semibold" marginTop={6} marginBottom={3}>
                        Detalles del Inmueble:
                    </Text>
                    <ExpandableText>{property.description || ""}</ExpandableText>
                </VStack>
                <AspectRatio
                    ratio={16 / 9}
                    width="100%"
                    overflow="hidden"
                    marginTop={9}
                    borderRadius="2xl"
                >
                    <MapDetailPage lat={lat} lng={lng}/>
                </AspectRatio>

                <Box h="600px"></Box>
            </Box>
            <Card
                display="flex"
                width="100%"
                position="sticky" // o puedes usar "sticky" si prefieres
                zIndex={12}
                bottom="0"
                height="7dvh"
                minWidth="max-content"
                alignItems="center"
                gap="1"
                paddingX={3}
            >
                <CardHeader width="100%" display="flex" justifyContent="center" alignItems="stretch">
                    <Text fontSize="2xl">${property.price.toLocaleString("es-CO")}</Text>
                    <Spacer/>
                    <ButtonGroup>
                        <ContactButton/>
                        <WhatsAppButton mensaje={mensajeWhatsApp}/>
                    </ButtonGroup>
                </CardHeader>
            </Card>
            <ModalFormContact/>
            <ShareModal title={property.title}/>
        </>
    );
};

export default PropertyDetailMobile;
