const RulerSquare = () => (
  <svg
    fill="currentColor"
    width="16px"
    height="16px"
    version="1.1"
    id="Layer_1"
    style={{ marginTop: "3px" }}
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    // xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M495.304,378.435H133.565V16.696C133.565,7.475,126.09,0,116.87,0H16.696C7.475,0,0,7.475,0,16.696v478.609
           c0,0.017,0.002,0.032,0.002,0.049C0.029,504.554,7.521,512,16.696,512h478.609c9.22,0,16.696-7.475,16.696-16.696V395.13
           C512,385.91,504.525,378.435,495.304,378.435z M33.391,345.043h16.696c9.22,0,16.696-7.475,16.696-16.696
           s-7.475-16.696-16.696-16.696H33.391V244.87h16.696c9.22,0,16.696-7.475,16.696-16.696c0-9.22-7.475-16.696-16.696-16.696H33.391
           v-66.783h16.696c9.22,0,16.696-7.475,16.696-16.696s-7.475-16.696-16.696-16.696H33.391V33.391h66.783v354.824l-66.783,66.783
           V345.043z M478.609,478.609h-77.913v-16.696c0-9.22-7.475-16.696-16.696-16.696s-16.696,7.475-16.696,16.696v16.696h-66.783
           v-16.696c0-9.22-7.475-16.696-16.696-16.696c-9.22,0-16.696,7.475-16.696,16.696v16.696h-66.783v-16.696
           c0-9.22-7.475-16.696-16.696-16.696c-9.22,0-16.696,7.475-16.696,16.696v16.696H57.002l66.783-66.783h354.824V478.609z"
        />
      </g>
    </g>
  </svg>
);

export default RulerSquare;
