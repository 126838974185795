import { Button, Icon, IconButton, Text } from "@chakra-ui/react";
import { RxShare2 } from "react-icons/rx";
import { useModalShareStore } from "../../store/modalStore";

interface Props {
  size?: "sm" | "lg";
}

const ShareButton = ({ size }: Props) => {
  const { openModal } = useModalShareStore();

  const commonStyles = {
    zIndex: 18,

    onClick: openModal,
  };

  const isLarge = size === "lg";

  return (
    <Button
      colorScheme="gray"
      {...commonStyles}
      borderRadius={isLarge ? "md" : "100%"}
      leftIcon={<Icon as={RxShare2} boxSize={6} marginLeft={2} />}
      width={isLarge ? "auto" : "39px"}
      height={isLarge ? "auto" : "39px"}
      paddingY={isLarge ? 1 : undefined}
      boxSize={isLarge ? "auto" : "39px"}
      aria-label={isLarge ? undefined : "Compartir"}
    >
      {isLarge && "Compartir"}
    </Button>
  );
};

export default ShareButton;
