import { useQuery } from "@tanstack/react-query";
import ms from "ms";
import APIClient from "../services/api-client";
import APIResponse from "../entities/APIResponse";
import usePropertyQueryStore from "../store/usePropertyQueryStore";

const apiClient = new APIClient("/propiedades");

const useProperties = () => {
  const propertyQuery = usePropertyQueryStore((s) => s.propertyQuery);
  return useQuery<APIResponse, Error>({
    queryKey: ["propiedades", propertyQuery],
    queryFn: () =>
      apiClient.getAll({
        params: {
          estado: propertyQuery.estado,
          tipo_propiedad: propertyQuery.tipo_propiedad,
          search: propertyQuery.searchText,
          price_range_min: propertyQuery.price_range_min,
          price_range_max: propertyQuery.price_range_max,
        },
      }),
    staleTime: ms("24h"),
  });
};

export default useProperties;
