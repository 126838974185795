import { Box, HStack, Spacer, Skeleton, SkeletonText } from "@chakra-ui/react";

const PropertyDetailMobileSkeleton = () => {
  return (
    <>
      <Box width="100%">
        <HStack
          justifyContent="space-between"
          position="absolute"
          zIndex={9}
          width="100%"
          paddingTop={2}
          paddingX={2}
        ></HStack>
        <Skeleton height="27rem" />
      </Box>
      <Box paddingX={3} marginTop={2}>
        <Box>
          <SkeletonText
            noOfLines={2}
            height="30px"
            maxWidth="15rem"
            skeletonHeight="6"
          />
        </Box>
        <Spacer />
        <HStack
          justifyContent={{
            base: "space-around",
            md: "flex-end",
          }}
          gap={2}
          width={{
            base: "100%",
            md: "39%",
          }}
        ></HStack>
      </Box>
    </>
  );
};

export default PropertyDetailMobileSkeleton;
