import { useParams } from "react-router-dom";
import useProperty from "../hooks/useProperty";

import PropertyDetailPage from "../components/PropertyDetailPage";
import PropertyDetailMobile from "../components/PropertyDetailMobile";
import { useMediaQuery } from "@chakra-ui/react";
import PropertyDetailSkeleton from "../components/PropertyDetailSkeleton";
import PropertyDetailMobileSkeleton from "../components/PropertyDetailMobileSkeleton";

const PropertyDetailLayout = () => {
  const { slug } = useParams();
  const { data, isLoading, error } = useProperty(slug!);
  const [isSmallerThan992] = useMediaQuery("(max-width: 993px)");

  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      {isLoading && (
        <>
          {isSmallerThan992 ? (
            <PropertyDetailMobileSkeleton />
          ) : (
            <PropertyDetailSkeleton />
          )}
        </>
      )}
      {data && (
        <>
          {isSmallerThan992 ? (
            <PropertyDetailMobile property={data} />
          ) : (
            <PropertyDetailPage property={data} />
          )}
        </>
      )}
    </>
  );

  // isSmallerThan992 ? (
  //   <PropertyDetailMobile />
  // ) : (
  //   <>
  //     <PropertyDetailPage />
  //   </>
  // );
};

export default PropertyDetailLayout;
