import { useColorModeValue, useColorMode } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";

const AboutUs = () => {
  const svgColor = useColorModeValue("#000031", "#d6d1d1"); // Color para light mode y dark mode respectivamente
  const { colorMode } = useColorMode();

  return (
    <Box
      minH="100vh"
      display="grid"
      placeItems="center"
      filter="contrast(180%) brightness(120%)"
      bgGradient="linear(-45deg, var(--color-1), var(--color-2))"
      bgSize="150% 100%"
      //   animation="bg 3s ease infinite reverse"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_1"
        data-name="Capa 1"
        viewBox="0 0 164 44.86"
        width={900}
        style={{
          transition: "transform 0.21s ease-in-out", // Transición suave para el efecto de escala
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform = "scale(1.02)";
          if (colorMode === "dark") {
            e.currentTarget.style.fill = "red";
          }
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = "scale(1)";
        }}

        // _hover={{ color: hoverSvgColor }} // Aplicando efecto de hover
      >
        <defs>
          {/* <style>
      .cls-1 {
        fill: #000031;
      }

      .cls-1, .cls-2 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #cb913c;
      }
    </style> */}
        </defs>
        <path
          className="cls-1"
          fill="#d6d1d1"
          d="m39.35,21.36l-2.63-1.58c-1.75-.97-2.72-1.46-2.92-1.46-.08,0-.93.5-2.57,1.49-1.63.99-2.57,1.59-2.8,1.78-.04.08.02.2.18.35.16.16.23.99.23,2.51v.64c0,.93-.1,1.53-.29,1.78-.2.25-.8.67-1.81,1.26-.39.23-.95.58-1.69,1.05-.27.23-1.2.79-2.77,1.66-1.58.88-2.42,1.31-2.54,1.31-.23,0-1.83-.86-4.79-2.57l-4.44-2.63v-2.74l.06-2.8-2.51-1.52c-1.67-1.05-2.59-1.58-2.74-1.58-.2,0-1.13.51-2.8,1.52l-2.51,1.52v12.17l1.93,1.11c.41.23.76.43,1.05.6l16.7,9.61,9.88-5.66s.06-.04.1-.06l.03-.02,9.73-5.57c0-.72-.06-12.18-.06-12.18m-19.62,16.4s-.08-.02-.15-.05c-.61-.25-2.93-1.55-6.97-3.92l-6.89-4.03v-5.08l1.75-1.05c1.21-.7,1.87-1.05,1.98-1.05.16,0,.23.82.23,2.45l.06,2.51,3.91,2.34c3.11,1.79,4.79,2.74,5.02,2.86l1.11.58,2.74-1.58c2.02-1.17,3.15-1.83,3.39-1.98.78-.54,1.52-.99,2.22-1.34l1.58-.99c.04-.54.06-1.35.06-2.42s.02-1.88.06-2.42c.39.23,1,.58,1.84,1.05.84.47,1.47.82,1.9,1.05v2.57l-.06,2.57-2.34,1.34c-.47.27-1.62.95-3.44,2.04-.74.51-2.22,1.39-4.44,2.66-1.8,1.03-2.93,1.64-3.37,1.83-.1.04-.17.07-.19.07 "
        />
        <rect className="cls-2" x="46.35" y="9.11" width=".81" height="25.67" fill="#cb913c" />
        <path
          className="cls-2"
          fill="#cb913c"
          d="m51.45,22.02v-.07c0-7.23,5.28-13.28,12.62-13.28,4.84,0,7.37,1.8,9.76,4.07l-.55.55c-2.05-1.98-4.66-3.89-9.24-3.89-6.82,0-11.77,5.8-11.77,12.51v.07c0,6.79,5.02,12.51,11.77,12.51,4.18,0,7.3-1.98,9.35-4.18l.59.55c-2.24,2.35-5.47,4.36-9.98,4.36-7.23,0-12.54-5.94-12.54-13.2"
        />
        <path
          className="cls-2"
          fill="#cb913c"
          d="m74.89,21.99v-.07c0-7.01,4.99-13.24,12.76-13.24s12.76,6.24,12.76,13.24v.07c0,7.01-4.99,13.24-12.76,13.24s-12.76-6.24-12.76-13.24m24.72.04v-.07c0-6.57-4.84-12.54-11.99-12.54s-11.92,5.87-11.92,12.47v.07c0,6.57,4.84,12.54,11.99,12.54s11.92-5.87,11.92-12.47"
        />
        <polygon
          className="cls-2"
          fill="#cb913c"
          points="104.45 9.11 105.15 9.11 124.66 33.47 125.71 34.79 124.73 34.79 105.22 10.43 105.22 34.79 104.45 34.79 104.45 9.11"
        />
        <rect className="cls-1" x="135.65" y="9.06" width="2.91" height="25.74" fill="#d6d1d1" />
        <path
          fill="#d6d1d1"
          className="cls-1"
          d="m157.65,9.52c1.03.31,1.91.74,2.64,1.29.73.55,1.3,1.23,1.69,2.02.39.8.59,1.69.59,2.67,0,.86-.12,1.61-.35,2.24-.23.64-.54,1.2-.94,1.69-.39.49-.83.9-1.3,1.23-.48.33-.97.61-1.49.83.76.22,1.47.51,2.15.86.67.36,1.25.78,1.74,1.27.49.49.88,1.08,1.17,1.77.29.69.44,1.47.44,2.35,0,1.1-.22,2.1-.66,2.98-.44.88-1.07,1.62-1.89,2.22-.82.6-1.8,1.06-2.96,1.38-1.15.32-2.42.48-3.82.48h-11.42V9.06h10.94c1.27,0,2.42.15,3.45.46m.36,9.83c1.1-.79,1.65-1.95,1.65-3.47,0-1.3-.49-2.34-1.47-3.1-.98-.76-2.38-1.14-4.22-1.14h-7.81v8.9h7.59c1.74,0,3.15-.39,4.25-1.18m1.4,11.66c1.12-.81,1.69-1.96,1.69-3.43s-.57-2.53-1.72-3.3c-1.15-.78-2.85-1.16-5.1-1.16h-8.11v9.12h8.62c1.96,0,3.5-.41,4.62-1.22"
        />
        <polygon
          fill="#d6d1d1"
          className="cls-1"
          points="132.01 34.79 129.7 34.79 113.12 13.73 109.41 9.11 112.73 9.11 132.93 34.81 132.01 34.79"
        />
        <path
          fill="#d6d1d1"
          className="cls-1"
          d="m13.66,4.22l6.15,3.5v21.53l-.5-.25c-.31-.14-1.38-.75-3.19-1.83l-2.69-1.58.04-6.78c.07-6.99.11-10.62.11-10.91l.1-3.68Zm10.53-1.92c-.53-.31-1.18-.69-1.96-1.13-.78-.44-1.21-.69-1.31-.74l-.75-.43c-1.9,1.06-3.94,2.25-6.12,3.55,1.85,1.07,6.16,3.6,6.16,3.6l6.22-3.51-2.24-1.33Zm2.51,12.42v10.95l-2.8,1.65c-.38.22-.84.48-1.38.81-.54.32-.95.56-1.22.72-.28.16-.44.25-.48.27l-.32.18V7.75l6.17-3.57.03,10.53Zm-.74-8.85l-4.66,2.66c-.12.07-.19.19-.2.33v.09c0,.14.14.23.26.16l4.66-2.65c.15-.08.24-.24.24-.41,0-.16-.17-.26-.31-.18m0,2.2l-4.66,2.66c-.12.07-.19.19-.2.33v.09c0,.14.14.23.26.16l4.66-2.65c.15-.08.24-.24.24-.41,0-.16-.17-.26-.31-.18m0,2.2l-4.66,2.66c-.12.07-.19.19-.2.33v.09c0,.14.14.23.26.16l4.66-2.65c.15-.08.24-.24.24-.41,0-.16-.17-.26-.31-.18m0,2.2l-4.66,2.66c-.12.07-.19.19-.2.33v.09c0,.14.14.23.26.16l4.66-2.65c.15-.08.24-.24.24-.41,0-.16-.17-.26-.31-.18"
        />
      </svg>
    </Box>
  );
};
export default AboutUs;
