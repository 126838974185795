import { useState } from "react";
import { FaEnvelope, FaWhatsapp, FaFacebook, FaTwitter } from "react-icons/fa"; // Importando iconos
import { FiCopy } from "react-icons/fi";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
  VStack,
  Grid,
  GridItem,
  ModalFooter,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import { useModalShareStore } from "../store/modalStore";

interface Props {
  title: string;
}

const ShareModal = ({ title }: Props) => {
  const location = useLocation();
  const [copied, setCopied] = useState(false);

  const { isOpen, openModal, closeModal } = useModalShareStore();

  const fullUrl = `${window.location.origin}${location.pathname}`;

  const shareOptions = [
    {
      icon: <FaEnvelope size={21} />,
      getUrl: () =>
        `mailto:?subject=Compartiendo una propiedad interesante&body=Mira esta propiedad: ${fullUrl}`,
      label: "Email",
    },
    {
      icon: (
        <FaWhatsapp
          style={{
            color: "white",
            backgroundColor: "#25D366",
            width: "1.8rem",
            height: "1.8rem",
            borderRadius: "100%",
          }}
        />
      ),
      getUrl: () =>
        `https://api.whatsapp.com/send?text=Mira%20esta%20propiedad:%20${encodeURIComponent(
          fullUrl
        )}`,
      label: "WhatsApp",
      color: "#25D366",
    },
    {
      icon: (
        <FaFacebook
          style={{
            color: "#0765FF",
            backgroundColor: "white",
            width: "1.8rem",
            height: "1.8rem",
            borderRadius: "100%",
          }}
        />
      ),
      getUrl: () => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(fullUrl)}`,
      label: "Facebook",
      color: "#3b5998",
    },
    // ... otros servicios
  ];
  const handleShare = (url: string) => {
    window.open(url, "_blank");
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(fullUrl);
    setCopied(true);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal} motionPreset="scale">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <VStack alignItems="flex-start">
              <Text as="h1" fontSize="lg">
                {" "}
                Comparte esta propiedad{" "}
              </Text>
              <Text fontSize="xl">{title} </Text>
            </VStack>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
              }}
              templateRows={{
                base: "repeat(4, 1fr)",
                md: "repeat(2, 1fr)",
              }}
              gap={5}
              overflow="hidden"
              width="100%"
            >
              <GridItem>
                <Button
                  onClick={handleCopy}
                  size="lg"
                  width="100%"
                  leftIcon={<FiCopy size={21} />}
                >
                  {copied ? "Copied!" : "Copy"}
                </Button>
              </GridItem>

              {shareOptions.map((option, index) => (
                <GridItem key={index}>
                  <Button
                    width="100%"
                    key={index}
                    leftIcon={option.icon}
                    onClick={() => handleShare(option.getUrl())}
                    size="lg"
                  >
                    {option.label}
                  </Button>
                </GridItem>
              ))}
            </Grid>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShareModal;
