export default {
    "items": [
        {
            "id": 1,
            "name": "Casas",
            "short_name": 'CS'
        },
        {
            "id": 2,
            "name": "Terrenos",
            "short_name": 'TR'
        },
        {
            "id": 3,
            "name": "Oficinas",
            "short_name": 'OF'
        },
        {
            "id": 4,
            "name": "Deptos.",
            "short_name": 'AP'
        },
        {
            "id": 5,
            "name": "Quintas",
            "short_name": 'QU'
        },
        {
            "id": 6,
            "name": "Edificios",
            "short_name": 'ED'
        },

    ]
}