import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { FaDollarSign } from "react-icons/fa";
import usePropertyQueryStore from "../store/usePropertyQueryStore";

const PropertyRangePriceSelector = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [priceRange, setPriceRange] = useState([0, 500_000]);

  const setSelectedPriceRangeMin = usePropertyQueryStore(
    (s) => s.setPriceRangeMin
  );
  const setSelectedPriceRangeMax = usePropertyQueryStore(
    (s) => s.setPriceRangeMax
  );

  const handleSliderChange = (values: [number, number]) => {
    setPriceRange(values);
    setSelectedPriceRangeMin(values[0]);
    setSelectedPriceRangeMax(values[1]);
  };

  const handleInputChange = (index: number, value: string) => {
    const newRange = [...priceRange];
    const numValue = Number(value);
    if (!isNaN(numValue)) {
      newRange[index] = numValue;
      setPriceRange(newRange);
      if (index === 0) {
        setSelectedPriceRangeMin(numValue);
      } else {
        setSelectedPriceRangeMax(numValue);
      }
    }
  };
  return (
    <Box marginLeft={3}>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<BsChevronDown />}
          borderRadius={4}
          onClick={onOpen}
        >
          Rango de Precios
        </MenuButton>
      </Menu>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Rango de Precios</ModalHeader>
          <ModalCloseButton />
          <ModalBody marginY={6}>
            <VStack spacing={9}>
              <RangeSlider
                aria-label={["min", "max"]}
                value={priceRange}
                onChange={handleSliderChange}
                defaultValue={[100000, 300000]}
                min={0}
                max={500_000}
                step={5_000}
              >
                <RangeSliderTrack bg="gray.200">
                  <RangeSliderFilledTrack bg="orange" />
                </RangeSliderTrack>
                <RangeSliderThumb boxSize={6} index={0}>
                  <Box as={FaDollarSign} color="gray.400" />
                </RangeSliderThumb>
                <RangeSliderThumb boxSize={6} index={1}>
                  <Box as={FaDollarSign} color="gray.400" />
                </RangeSliderThumb>
              </RangeSlider>
              <HStack spacing={9}>
                <Box>
                  <Text paddingBottom={1}>Mínimo</Text>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      fontSize="1.2em"
                      children="$"
                    />
                    <Input
                      variant="filled"
                      focusBorderColor="orange.300"
                      value={priceRange[0]}
                      onChange={(e) => handleInputChange(0, e.target.value)}
                      placeholder="Mínimo"
                    ></Input>
                  </InputGroup>
                </Box>
                <Box>
                  <Text paddingBottom={1}>Máximo</Text>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      fontSize="1.2em"
                      children="$"
                    />
                    <Input
                      variant="filled"
                      value={priceRange[1]}
                      focusBorderColor="orange.300"
                      onChange={(e) => handleInputChange(1, e.target.value)}
                      placeholder="Máximo"
                    ></Input>
                  </InputGroup>
                </Box>
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="flex-start" marginBottom={3}>
            <Button
              justifyContent="flex-start"
              colorScheme="orange"
              onClick={() => {
                // Actualizar el estado global al cerrar el modal
                setSelectedPriceRangeMin(priceRange[0]);
                setSelectedPriceRangeMax(priceRange[1]);
                onClose();
              }}
            >
              Aplicar Filtro
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PropertyRangePriceSelector;
