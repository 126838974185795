import { useQuery } from "@tanstack/react-query";
import APIClient from "../services/api-client";
import { Property } from "../entities/APIResponse";

const apiClient = new APIClient<Property>("/propiedades");

const useProperty = (slug: string) =>
  useQuery<Property, Error>({
    queryKey: ["propiedades", slug],
    queryFn: () => apiClient.get(slug),
  });

export default useProperty;
