import {
  AspectRatio,
  Container,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import ContactButton from "./Buttons/ContactButton";
import IconDetails from "./IconDetails";
import ModalFormContact from "./ModalFormContact";
import MapDetailPage from "./Map/MapDetailPage";
import ExpandableText from "./ExpandableText";
import NavBar from "./NavBar";
import WhatsAppButton from "./Buttons/WhatsAppButton";
import { Property } from "../entities/APIResponse";
import ImageModal from "./ImageModal";
import ImageModalButton from "./Buttons/ImageModalButton";
import ShareModal from "./ShareModal";
import ShareButton from "./Buttons/ShareButton";

interface Props {
  property: Property;
}

const PropertyDetailPage = ({ property }: Props) => {
  const fallbackSrc = "https://via.placeholder.com/300";
  const mensajeWhatsApp = `Hola, estoy interesado en esta propiedad, codigo:${
    property.code
  }. imagen:${property.images[0]?.image || "no disponible"}`;

  const image1 = property.images?.[0]?.image || fallbackSrc;
  const image2 = property.images?.[1]?.image || fallbackSrc;
  const image3 = property.images?.[2]?.image || fallbackSrc;

  const lat = property.latitud || -2.890359;
  const lng = property.longitud || -78.77785;

  return (
    <>
      <NavBar />
      <Container maxWidth="1200px">
        <HStack justifyContent="space-between" marginTop={6}>
          <Text as="h1" fontSize="3xl" fontWeight="light">
            {property.title}
          </Text>
          <ShareButton size="lg" />
        </HStack>
        <Grid
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(4, 1fr)"
          gap={3}
          overflow="hidden"
          width="100%"
          height="480px"
        >
          <GridItem
            rowSpan={2}
            colSpan={2}
            overflow="hidden"
            borderTopLeftRadius="2xl"
            borderBottomLeftRadius="2xl"
          >
            <AspectRatio ratio={2 / 2}>
              <Image src={image1} objectFit="cover" fallbackSrc={fallbackSrc} />
            </AspectRatio>
          </GridItem>
          <GridItem colSpan={2} overflow="hidden" borderTopEndRadius="2xl">
            <AspectRatio ratio={16 / 9}>
              <Image src={image2} fallbackSrc={fallbackSrc} />
            </AspectRatio>
          </GridItem>
          <GridItem
            colSpan={2}
            bg="blackAlpha.500"
            overflow="hidden"
            borderEndEndRadius="2xl"
            position="relative"
          >
            <AspectRatio ratio={16 / 9}>
              <Image src={image3} fallbackSrc={fallbackSrc} />
            </AspectRatio>
            <ImageModalButton numeroFotos={property.images.length} />
          </GridItem>
        </Grid>

        <Grid maxWidth="1200px" templateColumns="3fr 1fr" gap={9}>
          <GridItem>
            <HStack justifyContent="space-between">
              <HStack
                justifyContent="space-between"
                height="99px"
                marginY={5}
                width="100%"
              >
                <VStack alignItems="start" justifyItems="center">
                  <Text as="h1" fontSize="5xl" height="54px">
                    {" "}
                    ${property.price.toLocaleString("es-CO")}
                  </Text>
                  <Text as="h1" fontSize="2xl">
                    {property.canton},&nbsp;
                    {property.address}
                  </Text>
                </VStack>
                <HStack width="45%" justifyContent="space-between">
                  <IconDetails
                    size="lg"
                    tipoPropiedad={property.tipo_propiedad || "casa"}
                    areaConstruccion={property.area_construccion}
                    bedrooms={property.bedrooms}
                    bathrooms={property.bathrooms}

                  />
                </HStack>
              </HStack>
            </HStack>
            <VStack alignItems="flex-start" width="100%">
              <Text
                as="h3"
                fontSize="3xl"
                fontWeight="semibold"
                marginTop={9}
                marginBottom={3}
              >
                Detalles del Inmueble:
              </Text>
              <ExpandableText>{property.description || ""}</ExpandableText>
            </VStack>

            <AspectRatio
              ratio={16 / 9}
              width="100%"
              overflow="hidden"
              marginTop={9}
              borderRadius="2xl"
            >
              <MapDetailPage lat={lat} lng={lng} />
            </AspectRatio>
            <Container height="900px"></Container>
          </GridItem>
          <GridItem>
            <VStack
              marginTop={8}
              border={"1px solid"}
              borderColor={"gray.400"}
              borderRadius="xl"
              paddingY={2}
              position="sticky"
              top="3rem"
              zIndex={9}
            >
              <ContactButton size="lg" />
              <WhatsAppButton mensaje={mensajeWhatsApp} size="lg" />
            </VStack>
          </GridItem>
        </Grid>
      </Container>
      <ShareModal title={property.title} />
      <ModalFormContact />
      <ImageModal imagenes={property.images} size={"lg"} />
    </>
  );
};

export default PropertyDetailPage;
