import { createBrowserRouter } from "react-router-dom";
import GridMap from "../components/Map/GridMap";
import Layout from "../pages/Layout";
import HomePage from "../pages/HomePage";
import ErrorPage from "../pages/ErrorPage";
import Conocenos from "../pages/Conocenos";
import PropertyDetailLayout from "../pages/PropertyDetailLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "propiedades/:slug", element: <PropertyDetailLayout /> },
      { path: "mapa/", element: <GridMap /> },
      { path: "conocenos/", element: <Conocenos /> },
    ],
  },
]);

export default router;
