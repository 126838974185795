import { useMutation } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { ContactInfo } from "../entities/ContactInfo";
import APIClient from "../services/api-client";
import useModalContactStore from "../store/modalStore";

const apiClient = new APIClient<ContactInfo>("contacto/");

interface Props {
  resetForm: () => void;
}
const useFormContact = ({ resetForm }: Props) => {
  const { closeModal } = useModalContactStore();
  const toast = useToast();
  return useMutation<ContactInfo, Error, ContactInfo>(
    (form: ContactInfo) => apiClient.post(form),
    {
      onError: (error) => {
        toast({
          title: "Error al enviar el mensaje.",
          description:
            "Ocurrió un error al intentar enviar tu mensaje. Por favor, inténtalo de nuevo más tarde.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
      onSuccess: () => {
        closeModal();
        resetForm();
        toast({
          title: "Mensaje enviado.",
          description: "Nos pondremos en contacto contigo pronto.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );
};

export default useFormContact;
