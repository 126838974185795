import {useColorMode, IconButton} from "@chakra-ui/react";
import {BsFillMoonFill, BsSunFill} from "react-icons/bs";

const ColorModeSwitch = () => {
    const {colorMode, toggleColorMode} = useColorMode();
    return (
        <IconButton
            aria-label="Change color mode"
            borderRadius={6}
            icon={colorMode === "dark" ? <BsSunFill/> : <BsFillMoonFill/>}
            onClick={toggleColorMode}
        />
    );
};
export default ColorModeSwitch;
