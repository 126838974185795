import { Heading } from "@chakra-ui/react";
import useEstado from "../hooks/useEstado";
import useTipoPropiedad from "../hooks/useTipoPropiedad";
import usePropertyQueryStore from "../store/usePropertyQueryStore";

const PropertyHeading = () => {
  const estadoName = usePropertyQueryStore((s) => s.propertyQuery.estado);
  const tipoPropiedadName = usePropertyQueryStore(
    (s) => s.propertyQuery.tipo_propiedad
  );

  const estado = useEstado(estadoName);
  const tipoPropiedad = useTipoPropiedad(tipoPropiedadName)?.name;
  const buildHeading = () => {
    let heading = "Recién agregadas";
    if (tipoPropiedad && estado) {
      heading += `: ${tipoPropiedad} de ${estado}`;
    } else if (tipoPropiedad) {
      heading += `: ${tipoPropiedad}`;
    } else if (estado) {
      heading += `: ${estado}`;
    }
    return heading;
  };
  return (
    <Heading
      as="h1"
      marginBottom={2}
      fontSize={{
        base: "4xl",
        md: "5xl",
      }}
    >
      {buildHeading()}
    </Heading>
  );
};

export default PropertyHeading;
