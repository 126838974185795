import { Button } from "@chakra-ui/react";
import { BsFillTelephoneFill } from "react-icons/bs";

import useModalContactStore from "../../store/modalStore";

interface Props {
  size?: string;
}

const ContactButton = ({ size }: Props) => {
  const { openModal } = useModalContactStore();
  return (
    <Button
      onClick={openModal}
      width="63%"
      color="white"
      bg="#CB913C"
      leftIcon={<BsFillTelephoneFill />}
      size={size || "sm"}
      _hover={{ bg: "orange.300" }}
    >
      Contactar
    </Button>
  );
};

export default ContactButton;
