import { useState, memo } from "react";
import { Box, CloseButton, AspectRatio } from "@chakra-ui/react";
import { GoogleMap, MarkerF, OverlayView } from "@react-google-maps/api";
import { Property } from "../../entities/APIResponse";
import useProperties from "../../hooks/useProperties";
import PropertyCard from "../PropertyCard";
import PropertyCardContainer from "../PropertyCardContainer";
import { useGoogleMap } from "../../hooks/useGoogleMap";

const center = {
  lat: -2.890359,
  lng: -78.77785,
};

const MapComponent = () => {
  const { data, error, isLoading } = useProperties();
  const { isLoaded, onLoad, onUnmount } = useGoogleMap();
  const [selectedFeature, setSelectedFeature] = useState<Property | null>(null);
  const [isMapInteractive, setIsMapInteractive] = useState(true);

  if (error) return <Box>{error.message}</Box>;
  if (isLoading) return <Box>Loading...</Box>;

  const handleOverlayClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: isMapInteractive,
    gestureHandling: isMapInteractive ? "auto" : "none",
  };

  return isLoaded ? (
    <AspectRatio
      ratio={4 / 3}
      overflow={"hidden"}
      marginRight={{
        base: "0",
        lg: "1rem",
      }}
    >
      <GoogleMap
        mapContainerStyle={{ height: "81vh", borderRadius: "6px" }}
        zoom={10}
        center={center}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={mapOptions}
      >
        {data.results.map((propiedad) => {
          if (propiedad.latitud) {
            return (
              <MarkerF
                key={propiedad.id}
                position={{
                  lat: propiedad.latitud,
                  lng: propiedad.longitud,
                }}
                onClick={() => {
                  setSelectedFeature(propiedad);
                  setIsMapInteractive(false);
                }}
              >
                {selectedFeature === propiedad && (
                  <OverlayView
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    position={{
                      lat: propiedad.latitud,
                      lng: propiedad.longitud,
                    }}
                  >
                    <Box width="21rem">
                      <PropertyCardContainer>
                        <CloseButton
                          onClick={() => {
                            setSelectedFeature(null);
                            setIsMapInteractive(true);
                          }}
                          position="absolute"
                          left="2"
                          top="2"
                          size="sm"
                          zIndex="9"
                          opacity="0.8"
                          color="gray.700"
                          borderRadius="100%"
                          backgroundColor="white"
                        />
                        <PropertyCard property={selectedFeature} />
                      </PropertyCardContainer>
                    </Box>
                  </OverlayView>
                )}
              </MarkerF>
            );
          }
        })}
      </GoogleMap>
    </AspectRatio>
  ) : (
    <></>
  );
};

export default memo(MapComponent);
