const useEstado = (estado: string | undefined) => {
    const estadoName = [
        {id: 1, estado: 'AQ', name: 'Alquiler'},
        {id: 2, estado: 'EV', name: 'Venta'}
    ]

    return estadoName.find(item =>
        item.estado === estado)?.name || ''

}

export default useEstado;