import { Flex, Icon, Text, VStack } from "@chakra-ui/react";
import { IoBedOutline } from "react-icons/io5";
import { LuBath } from "react-icons/lu";
import iconMap from "../services/icon-map";
import RulerSquare from "./Icons/RulerSquare";

interface Props {
  size?: "sm" | "md" | "lg";
  tipoPropiedad: string;
  areaConstruccion?: number;
  bedrooms?: number;
  bathrooms?: number;
}

const IconDetails: React.FC<Props> = ({
  size = "sm",
  tipoPropiedad,
  areaConstruccion = 0,
  bedrooms = 0,
  bathrooms = 0,
}) => {
  const icono = iconMap(tipoPropiedad);
  const isLarge = size === "lg";
  const iconSize = isLarge ? "1.6em" : "1.2em";
  const fontSize = isLarge ? "21px" : "18px";
  const vStackSize = isLarge ? "4.5rem" : "3.3rem";
  const border = isLarge ? "1px solid" : "none";

  const getDisplayValue = (text: string, value: number) => {
    return text === "m²" ? `${value}m²` : value.toString();
  };

  const items = [
    { icon: RulerSquare, text: "m²", value: areaConstruccion },
    { icon: IoBedOutline, text: "habitaciones", value: bedrooms },
    { icon: LuBath, text: "baños", value: bathrooms },
  ];

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height={vStackSize}
        width={vStackSize}
        border={border}
        borderColor={"gray.400"}
        borderRadius="xl"
      >
        <Icon as={icono?.icon} boxSize={iconSize} />
        <Text fontSize={fontSize}>{icono?.singularName}</Text>
      </Flex>
      {items.map(({ icon, text, value }, index) => (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          key={text + index}
          height={vStackSize}
          width={vStackSize}
          border={border}
          borderColor={"gray.400"}
          borderRadius="xl"
        >
          <Icon as={icon} boxSize={iconSize} />
          <Text fontSize={fontSize}>{getDisplayValue(text, value)}</Text>
        </Flex>
      ))}
    </>
  );
};

export default IconDetails;
